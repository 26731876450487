<template>
  <div style="min-height: calc(100vh - 158px);">
   <div class="card shadow">
        <div class="card-body  p-sm-0">
            <h4 class="card-title mb-0">
              Welcome to {{ company.name }}
            </h4>
            <hr>
            
            <div class="card-body p-sm-0">
                <div class="row text-center" style="margin: 0 auto;">
                      <template v-for="(item, index) in modules" :key="index">
                          <div class="col-auto py-2 pageCard">
                              <ModuleCard 
                                  :item="item"
                              />
                          </div>
                      </template>
                </div>
              </div>
    </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import handleCompany from '@/services/modules/company'
import { inject } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

import ModuleCard from '@/components/molecule/company/ModuleCard'
export default {
  name: 'CompanyHome',

  components: {
    ModuleCard
  },

  methods: {
    ...mapMutations({
      setSidebar: 'company/setSidebar',
      setCompany: 'company/setCompany',
      setModules: 'company/setModules'
    }),
  },

  setup() {
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    const store = useStore()  

    const {
      fetchSidebar,
      fetchCompanyInfo,
      fetchCompanyModules,
      loading
    } = handleCompany()

    return {
      company: computed(() => store.state.company.company),
      modules: computed(() => store.state.company.modules),
      fetchSidebar,
      fetchCompanyInfo,
      fetchCompanyModules,
      loading,
      showError,
      showSuccess
    }
  }

}
</script>

<style scoped>
   .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(34, 41, 47, 0.125);
        border-radius: 15px;
    }
    .card-body{
        padding: 0;
        border-radius: 15px 15px 0 0 !important;
    }
    .card-title{
        background-color: #fff;
        margin: 0;
        padding:15px 15px;
        color: #7D7D7D;
        border-radius: 15px 15px 0 0;
        /* border-bottom: .5px solid #7D7D7D; */
    }
      .pageCard{
        box-sizing: border-box;
        /* background-color: #DBE7F2; */
        padding: 20px;
        box-sizing: border-box !important;
    }
    .pageCard:hover{
        box-sizing: border-box !important;
        cursor: pointer;
    }
    .pageCard p{
        display: -webkit-box;
        width: 130px;
        height: 40px;
        color: #1B73E6;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; 
        text-overflow: ellipsis; 
        line-height: 1.625;
    }
    hr{
        margin: 0;
    }
    @media screen and (max-width: 400px){

         .pageCard{
        padding: 0px;
        margin: 0px !important;
        /* background-color: #DBE7F2; */
        box-sizing: border-box !important;

    }
    .pageCard:hover{
        padding: 0px;
        margin: 0px !important;
        border-radius: 10px;
        box-sizing: border-box !important; 
    }
    /* .pageCard:hover>.avatar {
        border: 100px solid red;
    } */
    }
</style>
